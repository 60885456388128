// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

// ** Utils
import { buildQuery } from '@utils';

export const getHostings = createAsyncThunk('appHosting/getHostings', async (url, params) => {
  const response = await axios.get(`${url}/DirectAdminApi/api/show_all_users.php`, {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.length,
  };
});

// export const getHosting = createAsyncThunk(
//   "appHosting/getHosting",
//   async (id) => {
//     const response = await axios.get(`/api/v1/cloud-server/${id}`);
//     return response;
//   }
// );

// export const addHosting = createAsyncThunk(
//   "appHosting/addHosting",
//   async (cloudData, { dispatch, getState }) => {
//     const res = await axios.post("/api/v1/cloud-server", cloudData);
//     return res;
//   }
// );

// export const deleteHosting = createAsyncThunk(
//   "appHosting/deleteHosting",
//   async (id, { dispatch, getState }) => {
//     const res = await axios.delete(`/api/v1/cloud-server/${id}`);
//     return res;
//   }
// );

// export const updateHosting = createAsyncThunk(
//   "appHosting/updateHosting",
//   async (cloudData, { dispatch }) => {
//     const res = await axios.put(
//       `/api/v1/cloud-server/${cloudData.cloud_server_id}`,
//       cloudData
//     );
//     return res;
//   }
// );

export const appHostingSlice = createSlice({
  name: 'appHosting',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getHostings.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getHostings.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getHostings.rejected, (state, action) => {
        state.loading = false;
      });

    // // get item
    // .addCase(getHosting.pending, (state, action) => {
    //   state.selectedLoading = true;
    // })
    // .addCase(getHosting.fulfilled, (state, action) => {
    //   const data = action?.payload?.data?.data;
    //   state.selected = data;
    //   state.selectedLoading = false;
    // })
    // .addCase(getHosting.rejected, (state, action) => {
    //   state.selectedLoading = false;
    // })

    // // add
    // .addCase(addHosting.pending, (state, action) => {
    //   state.selectedLoading = true;
    // })
    // .addCase(addHosting.fulfilled, (state, action) => {
    //   const data = action?.payload?.data?.data;
    //   state.selected = data;
    //   state.data.unshift(data);
    //   state.total = state.total + 1;
    //   state.selectedLoading = false;
    // })
    // .addCase(addHosting.rejected, (state, action) => {
    //   state.selectedLoading = false;
    // })

    // // update
    // .addCase(updateHosting.pending, (state, action) => {
    //   state.selectedLoading = true;
    // })
    // .addCase(updateHosting.fulfilled, (state, action) => {
    //   const updatedData = action?.payload?.data?.data;
    //   const index = state.data.findIndex(
    //     (item) => item?.cloud_server_id === updatedData?.cloud_server_id
    //   );
    //   if (index !== -1) {
    //     state.data[index] = updatedData;
    //   }
    //   state.selected = updatedData;
    //   state.selectedLoading = false;
    // })
    // .addCase(updateHosting.rejected, (state, action) => {
    //   state.selectedLoading = false;
    // })

    // // delete
    // .addCase(deleteHosting.pending, (state, action) => {
    //   state.selectedLoading = true;
    // })
    // .addCase(deleteHosting.fulfilled, (state, action) => {
    //   state.selected = {};
    //   state.selectedLoading = false;
    //   state.data = state?.data?.filter(
    //     (item) =>
    //       item?.cloud_server_id !=
    //       action?.payload?.data?.data?.cloud_server_id
    //   );
    //   state.total = state.total - 1;
    // })
    // .addCase(deleteHosting.rejected, (state, action) => {
    //   state.selectedLoading = false;
    // });
  },
});
export default appHostingSlice.reducer;
