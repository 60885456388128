// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getPostTags = createAsyncThunk('appPostTags/getPostTags', async (params) => {
  const response = await axios.get('/api/v1/post-tags', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getPostTag = createAsyncThunk('appPostTags/getPostTag', async (id) => {
  const response = await axios.get(`/api/v1/post-tag/${id}`);
  return response;
});

export const addPostTag = createAsyncThunk('appPostTags/addPostTag', async (data, { dispatch, getState }) => {
  const newPostTag = await axios.post('/api/v1/post-tag', data);
  return newPostTag;
});

export const updatePostTag = createAsyncThunk('appPostTags/updatePostTag', async (data, { dispatch, getState }) => {
  const newPostTag = await axios.put(`/api/v1/post-tag/${data.post_tag_id}`, data);
  return newPostTag;
});

export const deletePostTag = createAsyncThunk('appPostTags/deletePostTag', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/post-tag/${id}`);
  return res;
});

export const appPostTagsSlice = createSlice({
  name: 'appPostTags',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getPostTags.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getPostTags.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getPostTags.rejected, (state, action) => {
        state.loading = false;
      })

      // ** get item
      .addCase(getPostTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getPostTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getPostTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addPostTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addPostTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addPostTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updatePostTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updatePostTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updatePostTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deletePostTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deletePostTag.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.post_tag_id != action?.payload?.data?.data?.post_tag_id);
        state.total = state.total - 1;
      })
      .addCase(deletePostTag.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appPostTagsSlice.reducer;
