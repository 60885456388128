import { lazy } from 'react';

const Home = lazy(() => import('../../views/W5Ds/pages/home'));
const Me = lazy(() => import('../../views/W5Ds/pages/me'));
const Pricing = lazy(() => import('../../views/W5Ds/pages/pricing'));
const Hosting = lazy(() => import('../../views/W5Ds/pages/hosting'));
const Domain = lazy(() => import('../../views/W5Ds/pages/domain'));
const Checkout = lazy(() => import('../../views/W5Ds/pages/checkout'));
const Profile = lazy(() => import('../../views/W5Ds/pages/user/profile'));
const Contact = lazy(() => import('../../views/W5Ds/pages/contact'));
const Payments = lazy(() => import('../../views/W5Ds/pages/payments'));
const Document = lazy(() => import('../../views/W5Ds/pages/document'));

const Templates = lazy(() => import('../../views/W5Ds/pages/templates/list'));
const TemplateCategory = lazy(() => import('../../views/W5Ds/pages/templates/category'));
const TemplatePreview = lazy(() => import('../../views/W5Ds/pages/templates/preview'));
const TemplateDetail = lazy(() => import('../../views/W5Ds/pages/templates/detail'));

const BlogList = lazy(() => import('../../views/W5Ds/pages/posts/list'));
const PostDetail = lazy(() => import('../../views/W5Ds/pages/posts/detail'));
const PostCategory = lazy(() => import('../../views/W5Ds/pages/posts/category'));

const W5DsRoutes = [
  {
    path: '/home',
    element: <Home />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/me',
    element: <Me />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/checkout',
    element: <Checkout />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/pricing',
    element: <Pricing />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/hostings',
    element: <Hosting />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/domains',
    element: <Domain />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/user/profile',
    element: <Profile />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  // posts routes
  {
    path: '/W5D/blogs',
    element: <BlogList />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/post/:id',
    element: <PostDetail />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/post-category/:id',
    element: <PostCategory />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/contact',
    element: <Contact />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/payments',
    element: <Payments />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/documents',
    element: <Document />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  // template routes
  {
    path: '/W5D/templates',
    element: <Templates />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/template-category/:id',
    element: <TemplateCategory />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/template/:id',
    element: <TemplateDetail />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
  {
    path: '/W5D/template/preview/:id',
    element: <TemplatePreview />,
    meta: {
      publicRoute: true,
      layout: 'blank',
    },
  },
];

export default W5DsRoutes;
