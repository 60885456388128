// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getProducts = createAsyncThunk('appProducts/getProducts', async (params) => {
  const response = await axios.get('/api/v1/products', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getProduct = createAsyncThunk('appProducts/getProduct', async (id) => {
  const response = await axios.get(`/api/v1/product/${id}`);
  return response;
});

export const getProductStatistics = createAsyncThunk('appProducts/getProductStatistics', async () => {
  const response = await axios.get(`/api/v1/product-statistics`);
  return response?.data?.data;
});

export const addProduct = createAsyncThunk('appProducts/addProduct', async (product, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/product', product);
  return res;
});

export const deleteProduct = createAsyncThunk('appProducts/deleteProduct', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/product/${id}`);
  return res;
});

export const updateProduct = createAsyncThunk('appProducts/updateProduct', async (productData, { dispatch }) => {
  const res = await axios.put(`/api/v1/product/${productData.product_id}`, productData);
  return res;
});

export const appProductsSlice = createSlice({
  name: 'appProducts',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getProducts.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getProduct.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getProductStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getProductStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getProductStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addProduct.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateProduct.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteProduct.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.product_id != action?.payload?.data?.data?.product_id);
        state.total = state.total - 1;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appProductsSlice.reducer;
