// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

export const getOptions = createAsyncThunk('options/getOptions', async () => {
  const response = await axios.get('/api/v1/options');
  return response.data.data;
});

export const getStatistics = createAsyncThunk('options/getStatistics', async () => {
  const response = await axios.get('/api/v1/statistics');
  return response.data.data;
});

export const createOrUpdateOptions = createAsyncThunk('options/createOrUpdateOptions', async (optionData, { dispatch }) => {
  const response = await axios.post('/api/v1/options', optionData);
  dispatch(getOptions());
  return response.data.data;
});

export const optionSlice = createSlice({
  name: 'options',
  initialState: {
    data: {},
    statistics: {},
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptions.pending, (state, action) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.data = { ...action.payload };
        state.loading = false;
      })
      .addCase(getOptions.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getStatistics.fulfilled, (state, action) => {
        state.statistics = { ...action.payload };
      })

      .addCase(createOrUpdateOptions.pending, (state, action) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(createOrUpdateOptions.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createOrUpdateOptions.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default optionSlice.reducer;
