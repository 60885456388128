// ** React Imports
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin';

// ** Third Party Components
import classnames from 'classnames';

const W5DsLayout = () => {
  // ** States
  const [isMounted, setIsMounted] = useState(false);

  // ** Hooks
  const { skin } = useSkin();

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={classnames('w5d-page', {
        'dark-layout': skin === 'dark',
      })}
    >
      <div className="app-content content ">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="container-xxl">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default W5DsLayout;
