// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getSliderCates = createAsyncThunk('appSliderCates/getSliderCates', async (params) => {
  const response = await axios.get('/api/v1/slider-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getSliderCate = createAsyncThunk('appSliderCates/getSliderCate', async (id) => {
  const response = await axios.get(`/api/v1/slider-cate/${id}`);
  return response;
});

export const addSliderCate = createAsyncThunk('appSliderCates/addSliderCate', async (data, { dispatch, getState }) => {
  const newSliderCate = await axios.post('/api/v1/slider-cate', data);
  return newSliderCate;
});

export const updateSliderCate = createAsyncThunk('appSliderCates/updateSliderCate', async (data, { dispatch, getState }) => {
  const newSliderCate = await axios.put(`/api/v1/slider-cate/${data.slider_cate_id}`, data);
  return newSliderCate;
});

export const deleteSliderCate = createAsyncThunk('appSliderCates/deleteSliderCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/slider-cate/${id}`);
  return res;
});

export const appSliderCatesSlice = createSlice({
  name: 'appSliderCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getSliderCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getSliderCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getSliderCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getSliderCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getSliderCate.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.selectedLoading = false;
      })
      .addCase(getSliderCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addSliderCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addSliderCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addSliderCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateSliderCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateSliderCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateSliderCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteSliderCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteSliderCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.slider_cate_id != action?.payload?.data?.data?.slider_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteSliderCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appSliderCatesSlice.reducer;
