// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

export const getAddresses = createAsyncThunk('addresses/getAddresses', async () => {
  const response = await axios.get('/api/v1/addresses');
  return response.data.data;
});

export const getCities = createAsyncThunk('cities/getCities', async () => {
  const response = await axios.get('/api/v1/cities');
  return response.data.data;
});

export const getDistricts = createAsyncThunk('districts/getDistricts', async (cityId) => {
  const response = await axios.get(`/api/v1/districts/${cityId}`);
  return response.data.data;
});

export const getCommunesWards = createAsyncThunk('communes-wards/getCommunesWards', async (data) => {
  const response = await axios.get(`/api/v1/communes-wards/${data.cityId}`, {
    params: {
      districtId: `${data.districtId}`,
    },
  });
  return response.data.data;
});

export const addressSlice = createSlice({
  name: 'addresses',
  initialState: {
    addresses: [],
    cities: [],
    districts: [],
    communesWards: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      //addresses
      .addCase(getAddresses.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.addresses = action.payload;
        state.loading = false;
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.loading = false;
      })

      //cities
      .addCase(getCities.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.loading = false;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.loading = false;
      })

      //districts
      .addCase(getDistricts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.districts = action.payload;
        state.loading = false;
      })
      .addCase(getDistricts.rejected, (state, action) => {
        state.loading = false;
      })

      //communes-wards
      .addCase(getCommunesWards.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCommunesWards.fulfilled, (state, action) => {
        state.communesWards = action.payload;
        state.loading = false;
      })
      .addCase(getCommunesWards.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default addressSlice.reducer;
