// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getSliders = createAsyncThunk('appSliders/getSliders', async (params) => {
  const response = await axios.get('/api/v1/sliders', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getSlider = createAsyncThunk('appSliders/getSlider', async (id) => {
  const response = await axios.get(`/api/v1/slider/${id}`);
  return response;
});

export const getSliderStatistics = createAsyncThunk('appSliders/getSliderStatistics', async () => {
  const response = await axios.get(`/api/v1/slider-statistics`);
  return response?.data?.data;
});

export const addSlider = createAsyncThunk('appSliders/addSlider', async (data, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/slider', data);
  return res;
});

export const deleteSlider = createAsyncThunk('appSliders/deleteSlider', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/slider/${id}`);
  return res;
});

export const updateSlider = createAsyncThunk('appSliders/updateSlider', async (data, { dispatch }) => {
  const res = await axios.put(`/api/v1/slider/${data.slider_id}`, data);
  return res;
});

export const appSlidersSlice = createSlice({
  name: 'appSliders',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getSliders.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getSliders.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getSliders.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getSlider.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getSlider.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.selectedLoading = false;
      })
      .addCase(getSlider.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getSliderStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getSliderStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getSliderStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addSlider.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addSlider.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addSlider.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateSlider.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateSlider.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateSlider.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteSlider.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.slider_id != action?.payload?.data?.data?.slider_id);
        state.total = state.total - 1;
      })
      .addCase(deleteSlider.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appSlidersSlice.reducer;
