// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getProjectCates = createAsyncThunk('appProjectCates/getProjectCates', async (params) => {
  const response = await axios.get('/api/v1/project-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getProjectCate = createAsyncThunk('appProjectCates/getProjectCateCate', async (id) => {
  const response = await axios.get(`/api/v1/project-cate/${id}`);
  return response;
});

export const addProjectCate = createAsyncThunk('appProjectCates/addProjectCate', async (data, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/project-cate', data);
  return res;
});

export const deleteProjectCate = createAsyncThunk('appProjectCates/deleteProjectCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/project-cate/${id}`);
  return res;
});

export const updateProjectCate = createAsyncThunk('appProjectCates/updateProjectCate', async (data, { dispatch }) => {
  const res = await axios.put(`/api/v1/project-cate/${data.project_cate_id}`, data);
  return res;
});

export const appProjectCatesSlice = createSlice({
  name: 'appProjectCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getProjectCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getProjectCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getProjectCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getProjectCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getProjectCate.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.selectedLoading = false;
      })
      .addCase(getProjectCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addProjectCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addProjectCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addProjectCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateProjectCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateProjectCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateProjectCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteProjectCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteProjectCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.project_cate_id != action?.payload?.data?.data?.project_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteProjectCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appProjectCatesSlice.reducer;
