import ReactPaginate from 'react-paginate';
import { Col, Input, Row } from 'reactstrap';
import { DefaultRoute } from '../router/routes';
import defaultImage from '@src/assets/images/dropzone/default-image.jpg';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return DefaultRoute;
  if (userRole === 'client') return '/access-control';
  return '/home';
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

// ** Get the image path
export const getImageUrl = (img, defaultImg) => {
  if (img) {
    return `${import.meta.env.VITE_API_URL}/${img}`;
  }
  return defaultImg ? defaultImg : defaultImage;
};

// ** Build filter query
export const buildQuery = (params, overrideParams = {}) => {
  let newParams = {
    isPaginate: false,
    // page: params?.page,
    // perPage: params?.perPage,
  };
  if (params.keyword) {
    newParams.keyword = params.keyword;
  }
  if (params.order) {
    newParams.order = params.order;
  }
  if (params.orderBy) {
    newParams.orderBy = params.orderBy;
  }
  if (params.status) {
    newParams.filter_key_1 = 'status';
    newParams.filter_value_1 = params.status;
  }
  if (params.start_date) {
    newParams.start_date = params.start_date;
  }
  if (params.end_date) {
    newParams.end_date = params.end_date;
  }
  if (params.filter_date_by) {
    newParams.filter_date_by = params.filter_date_by;
  }
  if (params.category) {
    newParams.filter_key_2 = 'post_cate_id';
    newParams.filter_value_2 = params.category;
  }
  if (params.author) {
    newParams.filter_key_3 = 'created_by';
    newParams.filter_value_3 = params.author;
  }
  if (typeof params.gender !== 'undefined' && params.gender !== '') {
    newParams.filter_key_4 = 'gender';
    newParams.filter_value_4 = params.gender;
  }
  return { ...newParams, ...overrideParams };
};

export const generateSlug = (str) => {
  str = str.toLowerCase();
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  str = str.replace(/[đĐ]/g, 'd');
  str = str.replace(/([^0-9a-z-\s])/g, '');
  str = str.replace(/(\s+)/g, '-');
  str = str.replace(/-+/g, '-');
  str = str.replace(/^-+|-+$/g, '');

  return str;
};

// ** Custom Pagination
export const CustomPagination = (props) => {
  const { rowCount, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;
  const count = Number(Math.ceil(rowCount / rowsPerPage));
  return (
    <div>
      <Row>
        <Col xl="6" className="d-flex align-items-center">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page" style={{ paddingLeft: '20px' }}>
              Show
            </label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={(e) => {
                onChangeRowsPerPage(Number(e?.target?.value));
                onChangePage(1);
              }}
              style={{ width: '5rem' }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </Input>
            <label htmlFor="rows-per-page">Entries</label>
          </div>
        </Col>
        <Col xl="6">
          <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            pageCount={count || 1}
            activeClassName="active"
            onPageChange={(page) => {
              onChangePage(page?.selected + 1);
            }}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
          />
        </Col>
      </Row>
    </div>
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
