// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getPermissionCates = createAsyncThunk('appPermissionCates/getPermissionCates', async (params) => {
  const response = await axios.get('/api/v1/user-permission-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getPermissionCate = createAsyncThunk('appPermissionCates/getPermissionCate', async (id) => {
  const response = await axios.get(`/api/v1/user-permission-cate/${id}`);
  return response;
});

export const addPermissionCate = createAsyncThunk('appPermissionCates/addPermissionCate', async (permission, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/user-permission-cate', permission);
  return res;
});

export const deletePermissionCate = createAsyncThunk('appPermissionCates/deletePermissionCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/user-permission-cate/${id}`);
  return res;
});

export const updatePermissionCate = createAsyncThunk('appPermissionCates/updatePermissionCate', async (permissionData, { dispatch }) => {
  const res = await axios.put(`/api/v1/user-permission-cate/${permissionData.permission_cate_id}`, permissionData);
  return res;
});

export const permissionCatesSlice = createSlice({
  name: 'appPermissionCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {
    selectPermissionCate: (state, action) => {
      if (action.payload === null) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getPermissionCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getPermissionCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.total = action.payload.total;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getPermissionCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getPermissionCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getPermissionCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.data.unshift(data);
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getPermissionCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addPermissionCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addPermissionCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addPermissionCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updatePermissionCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updatePermissionCate.fulfilled, (state, action) => {
        const updatedData = action?.payload?.data?.data;
        const index = state.data.findIndex((item) => item?.permission_cate_id === updatedData?.permission_cate_id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.selected = updatedData;
        state.selectedLoading = false;
      })
      .addCase(updatePermissionCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deletePermissionCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deletePermissionCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.permission_id != action?.payload?.data?.data?.permission_id);
        state.total = state.total - 1;
      })
      .addCase(deletePermissionCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export const { selectPermissionCate } = permissionCatesSlice.actions;

export default permissionCatesSlice.reducer;
