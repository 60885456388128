// ** Icons Import
import { FileText, Home, Settings, Circle, ShoppingCart, Users, Book, Image, Layout, Columns, Archive, Globe, BookOpen, Cloud } from 'react-feather';

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    badge: 'light-warning',
    navLink: '/dashboard/ecommerce',
  },
  {
    id: 'users',
    title: 'Users',
    icon: <Users size={20} />,
    navLink: '/apps/user',
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/user/list',
      },
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle size={12} />,
        navLink: '/apps/roles',
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Circle size={12} />,
        navLink: '/apps/permissions',
      },
    ],
  },
  {
    id: 'file',
    title: 'Files',
    icon: <Image size={20} />,
    navLink: '/apps/file',
  },
  {
    id: 'posts',
    title: 'Posts',
    icon: <Book size={20} />,
    navLink: '/apps/post',
    children: [
      {
        id: 'postList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/post/list',
      },
      {
        id: 'postAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/post/add',
      },
      {
        id: 'postCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/post-cate/list',
      },
      {
        id: 'postTagList',
        title: 'Tags',
        icon: <Circle size={12} />,
        navLink: '/apps/post-tag/list',
      },
    ],
  },
  {
    id: 'products',
    title: 'Products',
    icon: <ShoppingCart size={20} />,
    navLink: '/apps/product',
    children: [
      {
        id: 'productList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/product/list',
      },
      {
        id: 'productAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/product/add',
      },
      {
        id: 'productCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/product-cate/list',
      },
      {
        id: 'productTagList',
        title: 'Tags',
        icon: <Circle size={12} />,
        navLink: '/apps/product-tag/list',
      },
    ],
  },
  {
    id: 'invoiceApp',
    title: 'Invoice',
    icon: <FileText size={20} />,
    navLink: '/apps/invoice',
    children: [
      {
        id: 'invoiceList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/list',
      },
      {
        id: 'invoicePreview',
        title: 'Preview',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/preview',
      },
      {
        id: 'invoiceEdit',
        title: 'Edit',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/edit',
      },
      {
        id: 'invoiceAdd',
        title: 'Add',
        icon: <Circle size={12} />,
        navLink: '/apps/invoice/add',
      },
    ],
  },
  {
    id: 'projects',
    title: 'Projects',
    icon: <Archive size={20} />,
    navLink: '/apps/project',
    children: [
      {
        id: 'projectList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/project/list',
      },
      {
        id: 'projectAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/project/add',
      },
      {
        id: 'projectCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/project-cate/list',
      },
    ],
  },
  {
    id: 'templates',
    title: 'Templates',
    icon: <Layout size={20} />,
    navLink: '/apps/template',
    children: [
      {
        id: 'templateList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/template/list',
      },
      {
        id: 'templateAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/template/add',
      },
      {
        id: 'templateCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/template-cate/list',
      },
    ],
  },
  {
    id: 'sliders',
    title: 'Sliders',
    icon: <Columns size={20} />,
    navLink: '/apps/slider',
    children: [
      {
        id: 'sliderList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/slider/list',
      },
      {
        id: 'sliderAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/slider/add',
      },
      {
        id: 'sliderCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/slider-cate/list',
      },
    ],
  },
  {
    id: 'websites',
    title: 'Websites',
    icon: <Globe size={20} />,
    navLink: '/apps/website',
    children: [
      {
        id: 'websiteList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/website/list',
      },
      {
        id: 'websiteAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/website/add',
      },
      {
        id: 'websiteCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/website-cate/list',
      },
      {
        id: 'websiteTagList',
        title: 'Tags',
        icon: <Circle size={12} />,
        navLink: '/apps/website-tag/list',
      },
    ],
  },
  {
    id: 'documents',
    title: 'Documents',
    icon: <BookOpen size={20} />,
    navLink: '/apps/document',
    children: [
      {
        id: 'documentList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/document/list',
      },
      {
        id: 'documentAdd',
        title: 'Add new',
        icon: <Circle size={12} />,
        navLink: '/apps/document/add',
      },
      {
        id: 'documentCateList',
        title: 'Categories',
        icon: <Circle size={12} />,
        navLink: '/apps/document-cate/list',
      },
      {
        id: 'documentTagList',
        title: 'Tags',
        icon: <Circle size={12} />,
        navLink: '/apps/document-tag/list',
      },
    ],
  },
  {
    id: 'cloudSever',
    title: 'Cloud Sever',
    icon: <Cloud size={20} />,
    navLink: '/apps/cloud-sever',
  },
  {
    id: 'setting',
    title: 'Setting',
    icon: <Settings size={20} />,
    navLink: '/apps/setting',
  },
  // {
  //   id: "eCommerce",
  //   title: "eCommerce",
  //   icon: <ShoppingCart size={20} />,
  //   children: [
  //     {
  //       id: "shop",
  //       title: "Shop",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/ecommerce/shop",
  //     },
  //     {
  //       id: "detail",
  //       title: "Details",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/ecommerce/product-detail",
  //     },
  //     {
  //       id: "wishList",
  //       title: "Wish List",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/ecommerce/wishlist",
  //     },
  //     {
  //       id: "checkout",
  //       title: "Checkout",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/ecommerce/checkout",
  //     },
  //   ],
  // },
  // {
  //   id: "invoiceApp",
  //   title: "Invoice",
  //   icon: <FileText size={20} />,
  //   children: [
  //     {
  //       id: "invoiceList",
  //       title: "List",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/invoice/list",
  //     },
  //     {
  //       id: "invoicePreview",
  //       title: "Preview",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/invoice/preview",
  //     },
  //     {
  //       id: "invoiceEdit",
  //       title: "Edit",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/invoice/edit",
  //     },
  //     {
  //       id: "invoiceAdd",
  //       title: "Add",
  //       icon: <Circle size={12} />,
  //       navLink: "/apps/invoice/add",
  //     },
  //   ],
  // },
  // {
  //   id: "email",
  //   title: "Email",
  //   icon: <Mail size={20} />,
  //   navLink: "/apps/email",
  // },
  // {
  //   id: "chat",
  //   title: "Chat",
  //   icon: <MessageSquare size={20} />,
  //   navLink: "/apps/chat",
  // },
  // {
  //   id: "todo",
  //   title: "Todo",
  //   icon: <CheckSquare size={20} />,
  //   navLink: "/apps/todo",
  // },
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   icon: <Calendar size={20} />,
  //   navLink: "/apps/calendar",
  // },
  // {
  //   id: "kanban",
  //   title: "Kanban",
  //   icon: <CheckSquare size={20} />,
  //   navLink: "/apps/kanban",
  // },
];
