// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getTemplates = createAsyncThunk('appTemplates/getTemplates', async (params) => {
  const response = await axios.get('/api/v1/templates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getTemplate = createAsyncThunk('appTemplates/getTemplate', async (id) => {
  const response = await axios.get(`/api/v1/template/${id}`);
  return response;
});

export const getTemplateStatistics = createAsyncThunk('appTemplates/getTemplateStatistics', async () => {
  const response = await axios.get(`/api/v1/template-statistics`);
  return response?.data?.data;
});

export const addTemplate = createAsyncThunk('appTemplates/addTemplate', async (data, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/template', data);
  return res;
});

export const deleteTemplate = createAsyncThunk('appTemplates/deleteTemplate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/template/${id}`);
  return res;
});

export const updateTemplate = createAsyncThunk('appTemplates/updateTemplate', async (data, { dispatch }) => {
  const res = await axios.put(`/api/v1/template/${data.template_id}`, data);
  return res;
});

export const appTemplatesSlice = createSlice({
  name: 'appTemplates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getTemplates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getTemplates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getTemplate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.selected = action.payload;
        state.selectedLoading = false;
      })
      .addCase(getTemplate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getTemplateStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getTemplateStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getTemplateStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addTemplate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addTemplate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateTemplate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteTemplate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.template_id != action?.payload?.data?.data?.template_id);
        state.total = state.total - 1;
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appTemplatesSlice.reducer;
