// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getPermissions = createAsyncThunk('appPermissions/getPermissions', async (params) => {
  const response = await axios.get('/api/v1/user-permissions', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getPermission = createAsyncThunk('appPermissions/getPermission', async (id) => {
  const response = await axios.get(`/api/v1/user-permission/${id}`);
  return response;
});

export const addPermission = createAsyncThunk('appPermissions/addPermission', async (permission, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/user-permission', permission);
  return res;
});

export const deletePermission = createAsyncThunk('appPermissions/deletePermission', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/user-permission/${id}`);
  return res;
});

export const updatePermission = createAsyncThunk('appPermissions/updatePermission', async (permissionData, { dispatch }) => {
  const res = await axios.put(`/api/v1/user-permission/${permissionData.permission_id}`, permissionData);
  return res;
});

export const permissionCatesSlice = createSlice({
  name: 'appPermissions',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getPermissions.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.total = action.payload.total;
        state.params = action.payload.params;
        state.loading = false;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getPermission.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getPermission.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.data.unshift(data);
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getPermission.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addPermission.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addPermission.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addPermission.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updatePermission.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        const updatedData = action?.payload?.data?.data;
        const index = state.data.findIndex((item) => item?.permission_id === updatedData?.permission_id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.selected = updatedData;
        state.selectedLoading = false;
      })
      .addCase(updatePermission.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deletePermission.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.permission_id != action?.payload?.data?.data?.permission_id);
        state.total = state.total - 1;
      })
      .addCase(deletePermission.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export const { selectPermission } = permissionCatesSlice.actions;

export default permissionCatesSlice.reducer;
