// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getPostCates = createAsyncThunk('appPostCates/getPostCates', async (params) => {
  const response = await axios.get('/api/v1/post-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getPostCate = createAsyncThunk('appPostCates/getPostCate', async (id) => {
  const response = await axios.get(`/api/v1/post-cate/${id}`);
  return response;
});

export const addPostCate = createAsyncThunk('appPostCates/addPostCate', async (role, { dispatch, getState }) => {
  const newPostCate = await axios.post('/api/v1/post-cate', role);
  return newPostCate;
});

export const updatePostCate = createAsyncThunk('appPostCates/updatePostCate', async (role, { dispatch, getState }) => {
  const newPostCate = await axios.put(`/api/v1/post-cate/${role.post_cate_id}`, role);
  return newPostCate;
});

export const deletePostCate = createAsyncThunk('appPostCates/deletePostCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/post-cate/${id}`);
  return res;
});

export const appPostCatesSlice = createSlice({
  name: 'appPostCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getPostCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getPostCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getPostCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getPostCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getPostCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getPostCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addPostCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addPostCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addPostCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updatePostCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updatePostCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updatePostCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deletePostCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deletePostCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.post_cate_id != action?.payload?.data?.data?.post_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deletePostCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appPostCatesSlice.reducer;
