// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getUsers = createAsyncThunk('appUsers/getUsers', async (params) => {
  const response = await axios.get('/api/v1/users', {
    params: buildQuery(params, { filter_key_2: 'user_cate_id' }),
  });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length,
  };
});

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await axios.get(`/api/v1/user/${id}`);
  return response;
});

export const getUserStatistics = createAsyncThunk('appUsers/getUserStatistics', async () => {
  const response = await axios.get(`/api/v1/user-statistics`);
  return response?.data?.data;
});

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  const res = await axios.post('/api/v1/user', user);
  return res;
});

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/user/${id}`);
  return res;
});

export const updateUser = createAsyncThunk('appUsers/updateUser', async (data, { dispatch }) => {
  const res = await axios.put(`/api/v1/user/${data.user_id}`, data);
  return res;
});

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: true,
    statistics: null,
    statisticsLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get all
      .addCase(getUsers.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getUser.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // get statistics
      .addCase(getUserStatistics.pending, (state, action) => {
        state.statisticsLoading = true;
      })
      .addCase(getUserStatistics.fulfilled, (state, action) => {
        state.statistics = action.payload;
        state.statisticsLoading = false;
      })
      .addCase(getUserStatistics.rejected, (state, action) => {
        state.statisticsLoading = false;
      })

      // add
      .addCase(addUser.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.data.unshift(data);
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateUser.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const updatedData = action?.payload?.data?.data;
        const index = state.data.findIndex((item) => item?.user_id === updatedData?.user_id);
        if (index !== -1) {
          state.data[index] = updatedData;
        }
        state.selected = updatedData;
        state.selectedLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteUser.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.user_id != action?.payload?.data?.data?.user_id);
        state.total = state.total - 1;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});
export default appUsersSlice.reducer;
