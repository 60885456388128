// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FormData from 'form-data';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getFiles = createAsyncThunk('appFile/getFiles', async (params) => {
  const response = await axios.get('/api/v1/files', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
  };
});

export const getFile = createAsyncThunk('appPosts/getFile', async (id) => {
  const response = await axios.get(`/api/v1/post/${id}`);
  return response;
});

export const addFile = createAsyncThunk('appFile/addFile', async (file, { dispatch, getState }) => {
  let data = new FormData();
  data.append('file', file);
  const response = await axios.post('/api/v1/file', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
});

export const addFiles = createAsyncThunk('appFile/addFiles', async (files, { dispatch, getState }) => {
  let data = new FormData();

  files.forEach((file) => {
    data.append('files', file);
  });
  const response = await axios.post('/api/v1/files', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
});

export const updateFile = createAsyncThunk('appFile/updateFile', async (file, { dispatch, getState }) => {
  const response = await axios.put(`/api/v1/file/${file.file_id}`, file);
  return response.data;
});

export const deleteFile = createAsyncThunk('appFile/deleteFile', async (fileId, { dispatch, getState }) => {
  const response = await axios.delete(`/api/v1/file/${fileId}`);
  return response.data;
});

export const appFileSlice = createSlice({
  name: 'appFile',
  initialState: {
    files: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
    selectedFile: {},
  },
  reducers: {
    selectFile: (state, action) => {
      state.selectedFile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state, action) => {
        state.files = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        state.files = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getFiles.rejected, (state, action) => {
        state.loading = false;
      })

      // get file
      .addCase(getFile.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getFile.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getFile.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addFile.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addFile.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add multiple file
      .addCase(addFiles.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addFiles.fulfilled, (state, action) => {
        const newFiles = action?.payload?.data;
        state.selected = {};
        state.selectedLoading = false;
        state.files = newFiles.concat(state.files);
        state.total = state.total + newFiles?.length;
      })
      .addCase(addFiles.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateFile.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateFile.fulfilled, (state, action) => {
        const data = action?.payload?.data;
        state.selected = data;
        state.selectedLoading = false;
        state.files = state.files.map((item) => {
          if (item?.file_id === data?.file_id) {
            return data;
          }
          return item;
        });
      })
      .addCase(updateFile.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteFile.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.files = state?.files?.filter((item) => item?.file_id != action?.payload?.data?.file_id);
        state.total = state.total - 1;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export const { selectFile } = appFileSlice.actions;

export default appFileSlice.reducer;
