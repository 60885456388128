// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getDocumentTags = createAsyncThunk('appDocumentTags/getDocumentTags', async (params) => {
  const response = await axios.get('/api/v1/document-tags', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getDocumentTag = createAsyncThunk('appDocumentTags/getDocumentTag', async (id) => {
  const response = await axios.get(`/api/v1/document-tag/${id}`);
  return response;
});

export const addDocumentTag = createAsyncThunk('appDocumentTags/addDocumentTag', async (data, { dispatch, getState }) => {
  const newDocumentTag = await axios.post('/api/v1/document-tag', data);
  return newDocumentTag;
});

export const updateDocumentTag = createAsyncThunk('appDocumentTags/updateDocumentTag', async (data, { dispatch, getState }) => {
  const newDocumentTag = await axios.put(`/api/v1/document-tag/${data.document_tag_id}`, data);
  return newDocumentTag;
});

export const deleteDocumentTag = createAsyncThunk('appDocumentTags/deleteDocumentTag', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/document-tag/${id}`);
  return res;
});

export const appDocumentTagsSlice = createSlice({
  name: 'appDocumentTags',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getDocumentTags.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getDocumentTags.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getDocumentTags.rejected, (state, action) => {
        state.loading = false;
      })

      // ** get item
      .addCase(getDocumentTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getDocumentTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getDocumentTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addDocumentTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addDocumentTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addDocumentTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateDocumentTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateDocumentTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateDocumentTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteDocumentTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteDocumentTag.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.document_tag_id != action?.payload?.data?.data?.document_tag_id);
        state.total = state.total - 1;
      })
      .addCase(deleteDocumentTag.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appDocumentTagsSlice.reducer;
