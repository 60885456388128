// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getDocumentCates = createAsyncThunk('appDocumentCates/getDocumentCates', async (params) => {
  const response = await axios.get('/api/v1/document-cates', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getDocumentCate = createAsyncThunk('appDocumentCates/getDocumentCate', async (id) => {
  const response = await axios.get(`/api/v1/document-cate/${id}`);
  return response;
});

export const addDocumentCate = createAsyncThunk('appDocumentCates/addDocumentCate', async (role, { dispatch, getState }) => {
  const newDocumentCate = await axios.post('/api/v1/document-cate', role);
  return newDocumentCate;
});

export const updateDocumentCate = createAsyncThunk('appDocumentCates/updateDocumentCate', async (role, { dispatch, getState }) => {
  const newDocumentCate = await axios.put(`/api/v1/document-cate/${role.document_cate_id}`, role);
  return newDocumentCate;
});

export const deleteDocumentCate = createAsyncThunk('appDocumentCates/deleteDocumentCate', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/document-cate/${id}`);
  return res;
});

export const appDocumentCatesSlice = createSlice({
  name: 'appDocumentCates',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getDocumentCates.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getDocumentCates.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getDocumentCates.rejected, (state, action) => {
        state.loading = false;
      })

      // get item
      .addCase(getDocumentCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getDocumentCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getDocumentCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addDocumentCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addDocumentCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addDocumentCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateDocumentCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateDocumentCate.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateDocumentCate.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteDocumentCate.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteDocumentCate.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.document_cate_id != action?.payload?.data?.data?.document_cate_id);
        state.total = state.total - 1;
      })
      .addCase(deleteDocumentCate.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appDocumentCatesSlice.reducer;
