// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from '@axios';

// ** Utils
import { buildQuery } from '@utils';

export const getWebsiteTags = createAsyncThunk('appWebsiteTags/getWebsiteTags', async (params) => {
  const response = await axios.get('/api/v1/website-tags', {
    params: buildQuery(params),
  });
  return {
    params,
    data: response?.data?.data,
    totalPages: response?.data?.data?.length,
  };
});

export const getWebsiteTag = createAsyncThunk('appWebsiteTags/getWebsiteTag', async (id) => {
  const response = await axios.get(`/api/v1/website-tag/${id}`);
  return response;
});

export const addWebsiteTag = createAsyncThunk('appWebsiteTags/addWebsiteTag', async (data, { dispatch, getState }) => {
  const newWebsiteTag = await axios.post('/api/v1/website-tag', data);
  return newWebsiteTag;
});

export const updateWebsiteTag = createAsyncThunk('appWebsiteTags/updateWebsiteTag', async (data, { dispatch, getState }) => {
  const newWebsiteTag = await axios.put(`/api/v1/website-tag/${data.website_tag_id}`, data);
  return newWebsiteTag;
});

export const deleteWebsiteTag = createAsyncThunk('appWebsiteTags/deleteWebsiteTag', async (id, { dispatch, getState }) => {
  const res = await axios.delete(`/api/v1/website-tag/${id}`);
  return res;
});

export const appWebsiteTagsSlice = createSlice({
  name: 'appWebsiteTags',
  initialState: {
    data: [],
    loading: false,
    total: 0,
    params: {},
    selected: null,
    selectedLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all
      .addCase(getWebsiteTags.pending, (state, action) => {
        state.data = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getWebsiteTags.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = false;
      })
      .addCase(getWebsiteTags.rejected, (state, action) => {
        state.loading = false;
      })

      // ** get item
      .addCase(getWebsiteTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(getWebsiteTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(getWebsiteTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // add
      .addCase(addWebsiteTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(addWebsiteTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(addWebsiteTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // update
      .addCase(updateWebsiteTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(updateWebsiteTag.fulfilled, (state, action) => {
        const data = action?.payload?.data?.data;
        state.selected = data;
        state.selectedLoading = false;
      })
      .addCase(updateWebsiteTag.rejected, (state, action) => {
        state.selectedLoading = false;
      })

      // delete
      .addCase(deleteWebsiteTag.pending, (state, action) => {
        state.selectedLoading = true;
      })
      .addCase(deleteWebsiteTag.fulfilled, (state, action) => {
        state.selected = {};
        state.selectedLoading = false;
        state.data = state?.data?.filter((item) => item?.website_tag_id != action?.payload?.data?.data?.website_tag_id);
        state.total = state.total - 1;
      })
      .addCase(deleteWebsiteTag.rejected, (state, action) => {
        state.selectedLoading = false;
      });
  },
});

export default appWebsiteTagsSlice.reducer;
